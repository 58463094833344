import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import styled from 'styled-components';
import {useParams} from "react-router-dom";
import UserLivePanel from "../pubnub/panels/UserLivePanel";

export default function UserApp(props) {
    let {uuid}  = useParams();

    return (
        <Wrapper>
            <UserLivePanel uuid={uuid} />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;
