import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import './style.css'
import white_heart from './images/white_heart.svg'
import cal_white from './images/cal_white.svg'

function getClassNameByPerc(perc) {
    if (perc == undefined) {
        return 'none';
    }
    if (perc < 30) {
        return 'green';
    }
    if (perc < 50) {
        return 'blue';
    }
    if (perc < 80) {
        return 'yellow';
    }
    return 'red';
}

export default function UserTileView(props) {
    const {
        user,
        metrics,
        max_heart_rate = 180
    } = props;
    if (user == undefined) {
        return null;
    }
    let perc;
    if (metrics != undefined) {
        let {heart_rate} = metrics;
        if (heart_rate != undefined) {
            perc = 100.0 * (+heart_rate) / (+max_heart_rate);
        }
    }
    let wrapperClassName = getClassNameByPerc(perc);
    let isDebug = (window.location.href.indexOf('debug') > -1);

    return (
        <Wrapper className={wrapperClassName}>
            <InnerWrapper className={'tile_inner_overlay'}>
                <TopPlaceholder>
                    <TopLeft>
                        <Name onClick={() => {
                            if (isDebug == false) {
                                return;
                            }
                            window.open(`/u/${user.uuid}/debug`, '_blank').focus();
                        }}>
                            {user == undefined ? null : user.name}
                        </Name>
                    </TopLeft>
                    <TopRight>
                        {metrics == undefined ?
                            <NoMetrics>
                                {''}
                            </NoMetrics>
                            :
                            <TopMetricsPlaceholder>
                                <MetricLine>
                                    <MetricImage src={white_heart}/>
                                    <MetricVal>
                                            <span style={{fontSize: 28}}>
                                                {metrics.heart_rate}
                                            </span>
                                        {` bpm`}
                                    </MetricVal>
                                </MetricLine>
                                {metrics.calories == undefined ? null :
                                    <MetricLine>
                                        <MetricImage src={cal_white}/>
                                        <MetricVal>
                                            <span style={{fontSize: 28}}>
                                                {metrics.calories}
                                            </span>
                                            {` kcal`}
                                        </MetricVal>
                                    </MetricLine>
                                }
                            </TopMetricsPlaceholder>
                        }
                    </TopRight>
                </TopPlaceholder>

                {metrics == undefined ?
                    <CenterNoDataPlaceholder>
                        awaiting the data ...
                    </CenterNoDataPlaceholder>
                    :
                    <CenterPlaceholder>
                        {perc == undefined ? null :
                            `${Math.round(perc)}%`
                        }
                    </CenterPlaceholder>
                }

            </InnerWrapper>
        </Wrapper>
    );
}

const CenterNoDataPlaceholder = styled.div`
  box-sizing: border-box;
  text-align: center;
  padding: 15px;
  flex: 1;
  font-weight: 700;
  font-size: 30px;
  opacity: 0.6;
`;


const MetricLine = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: bold;
  align-items: center;

  :first-of-type {
    margin-bottom: 5px;
  }
`;

const MetricVal = styled.div`

`;

const MetricImage = styled.img`
  margin-right: 10px;
  height: 26px;
`;

const Name = styled.div`
  font-size: 28px;
  font-weight: bold;
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div`
  color: white;
  border-radius: 12px;
`;

const InnerWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  max-width: 100%;
`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TopLeft = styled.div`

`;

const TopRight = styled.div`
  margin-left: 10px;
  width: 130px;
`;

const CenterPlaceholder = styled.div`
  box-sizing: border-box;
  text-align: center;
  padding: 15px;
  flex: 1;
  font-weight: 700;
  font-size: 100px;
`;

const NoMetrics = styled.div`

`;

const TopMetricsPlaceholder = styled.div`

`;