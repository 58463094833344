import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import PubnubAPI from "../../../api/PubnubAPI";
import PubNub from "pubnub";
import CorsanoAPI from "../../../api/CorsanoAPI";

import {Code} from 'react-content-loader'
import UserTileView from "../tools/UserTileView";
import Spin from "arui-feather/spin";
import CmLoader from "../../loaders/CmLoader";

export default function UserLivePanel(props) {
    const {uuid} = props;

    const [loading, setLoading] = useState(false);
    const [dataMap, setDataMap] = useState(undefined);
    const [userData, setUserData] = useState(undefined);
    const pRef = useRef();

    useEffect(() => {
        setDataMap(PubnubAPI.getLatestValuesMapFromLocalStorage());
        setLoading(true);
        PubnubAPI.getConnectionData(uuid).then(pld => {
            console.log('conn data = ', pld);
            setLoading(false);
            pRef.current = new PubNub({
                publishKey: pld.publishKey,
                subscribeKey: pld.subscribeKey,
                uuid: uuid,
                authKey: pld.token
            });
            console.log('pRef.current = ', pRef.current);
            let channelName = `user-${uuid}`;
            pRef.current.subscribe({
                channels: [channelName]
            });
            pRef.current.addListener({
                status: async (statusEvent) => {
                    console.log('status: statusEvent = ', statusEvent);
                },
                message: (messageEvent) => {
                    let {message} = messageEvent;
                    console.log('message: messageEvent = ', messageEvent);
                    console.log('message: message = ', message);
                    PubnubAPI.savePointToLocalStorage(message);
                    setDataMap(PubnubAPI.getLatestValuesMapFromLocalStorage());
                },
                presence: (presenceEvent) => {
                    // handle presence
                },
            });
        });
        CorsanoAPI.getUserProfile(uuid).then(dd => {
            setUserData(dd);
        })
        return () => {
            pRef.current.unsubscribeAll();
        }
    }, [uuid]);

    if (userData == undefined) {
        return (
            <CmLoader/>
        )
    }
    let d = {
        user: userData,
        metrics: dataMap[uuid]
    }

    return (
        <Wrapper>
            {/*{JSON.stringify(d)}*/}
            <InnerPlaceholder>
                <UserTileView
                    {...d}
                />
            </InnerPlaceholder>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const InnerPlaceholder = styled.div`
  width: 520px;
  padding: 10px;
  margin: 0 auto;
  box-sizing: border-box;
  @media(max-width: 520px){
    width: 100%;
  }
`;