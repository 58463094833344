import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import PubNub from 'pubnub'
import PubnubAPI from "../../api/PubnubAPI";

export default function SimpleClientTool(props) {
    const {uuid = 'sabir'} = props;
    const [loading, setLoading] = useState(false);
    const [authData, setAuthData] = useState(undefined);
    const pRef = useRef();

    useEffect(() => {
        setLoading(true);
        PubnubAPI.getConnectionData(uuid).then(pld => {
            setAuthData(pld);
            setLoading(false);
            pRef.current = new PubNub({
                publishKey: pld.publishKey,
                subscribeKey: pld.subscribeKey,
                uuid: uuid,
                authKey: pld.token
            });
        });
    }, [uuid]);

    if (loading == true) {
        return (
            <Spin theme={'alfa-on-color'}/>
        )
    }

    return (
        <Wrapper>
            {/*{JSON.stringify(authData)}*/}
            <button onClick={async () => {
                pRef.current.publish({
                    channel: `user-${uuid}`,
                    message: {
                        calories: Math.floor(Math.random() * 50 + 400),
                        heart_rate: Math.floor(Math.random() * 100) + 60,
                        uuid: uuid,
                        t: +new Date()
                    },
                    meta: {
                        uuid: uuid
                    }
                }, (status, response) => {
                    console.log('status, response = ', status, response);
                })
            }}>
                publish something
            </button>
        </Wrapper>
    );
}

const Wrapper = styled.div`

`;