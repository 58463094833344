import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import styled from 'styled-components';
import {useSearchParams, useParams} from "react-router-dom";
import GroupLivePanel from "../pubnub/panels/GroupLivePanel";

export default function GroupApp(props) {
    let {groupCode}  = useParams();

    return (
        <Wrapper>
            {/*{groupCode}*/}
            <GroupLivePanel code={groupCode} />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;
