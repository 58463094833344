import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import styled from 'styled-components';
import {useParams} from "react-router-dom";
import SimpleClientTool from "../pubnub/SimpleClientTool";

export default function UserDebugApp(props) {
    let {uuid}  = useParams();

    return (
        <Wrapper>
            debug {uuid}
            <div>
                <SimpleClientTool
                    uuid={uuid}
                />
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;
