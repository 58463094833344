import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import IndexApp from './IndexApp'
import GroupApp from './GroupApp'
import UserApp from './UserApp'
import UserDebugApp from "./UserDebugApp";

export default function RouterApp() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={`/u/:uuid`} element={<UserApp/>}/>
                <Route path={`/u/:uuid/debug`} element={<UserDebugApp/>}/>
                <Route path={`/x/:groupCode`} element={<GroupApp/>}/>
                <Route path={`/`} element={<IndexApp/>}/>
                <Route path={`/*`} element={<Navigate to={`/`}/>}/>
            </Routes>
        </BrowserRouter>
    );
};

