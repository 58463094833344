import React from 'react';
import logo from './logo.svg';
import './App.css';
import RouterApp from "./components/apps/RouterApp";

import styled from 'styled-components'

import logo_img from './assets/images/cm_logo_wh.svg'

function App() {
    return (
        <Wrapper>
            <HeaderPlaceholder>
                <LogoImage src={logo_img} />
            </HeaderPlaceholder>

            <RouterApp/>
        </Wrapper>
    );
}

export default App;

const Wrapper = styled.div`

`;

const LogoImage = styled.img`
    height: 50px;
`;

const HeaderPlaceholder = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;