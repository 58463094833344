import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import HeartHelper from "../../../helpers/HeartHelper";

const DEFAULT_COLOR = 'white';

export default function HeartCard(props) {
    let {heartRate = 88.4, name = 'Sabir Shaykhlislamov'} = props;
    const color = HeartHelper.getColorByHeartRate(+heartRate);

    console.log('HeartCard: render: heartRate, name, color = ', heartRate, name, color);

    return (
        <Wrapper color={color} >
            <Name>
                {name}
            </Name>
            <HeartRate>
                <HearRateValue>
                    {heartRate}
                </HearRateValue>
            </HeartRate>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    font-family: sans-serif;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    background: ${props => (props.color == undefined ? DEFAULT_COLOR : props.color)};
    opacity: 0.9;
`;

const Name = styled.div`
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    height: 20%;
    display: flex;
    align-self: center;
    justify-content: center;
    color: white;
`;

const HeartRate = styled.div`
    height: 60%;
    display: flex;
    align-self: center;
    justify-content: center;
    font-weight: bold;
`;

const HearRateValue = styled.div`
    font-size: 80px;
    display: flex;
    align-self: center;
    justify-content: center;
`;
