import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import HeartRateDashboardTool from "../dashboard/tools/HeartRateDashboardTool";
import SimpleClientTool from "../pubnub/SimpleClientTool";

export default function IndexApp(props) {
    useEffect(() => {
        window.location.href = 'https://cardiomood.com';
    }, []);
    return (
        <Wrapper>
            {/*<HeartRateDashboardTool/>*/}
            {/*<SimpleClientTool/>*/}
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
