import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import PubnubAPI from "../../../api/PubnubAPI";
import PubNub from "pubnub";
import CorsanoAPI from "../../../api/CorsanoAPI";

import {Code} from 'react-content-loader'
import UserTileView from "../tools/UserTileView";
import Spin from "arui-feather/spin";
import CmLoader from "../../loaders/CmLoader";

export default function GroupLivePanel(props) {
    const {code} = props;

    const [loading, setLoading] = useState(false);
    const [dataMap, setDataMap] = useState(undefined);
    const [userData, setUserData] = useState(undefined);
    const [users, setUsers] = useState([]);
    const pRef = useRef();

    useEffect(() => {
        setDataMap(PubnubAPI.getLatestValuesMapFromLocalStorage());
        setLoading(true);
        PubnubAPI.getConnectionDataForGroup(code).then(pld => {
            console.log('conn data = ', pld);
            setLoading(false);
            setUsers(pld.users);
            pRef.current = new PubNub({
                publishKey: pld.publishKey,
                subscribeKey: pld.subscribeKey,
                uuid: code,
                authKey: pld.token
            });
            console.log('pRef.current = ', pRef.current);
            pRef.current.subscribe({
                channels: pld.users.map(x => `user-${x.uuid}`)
            });
            pRef.current.addListener({
                status: async (statusEvent) => {
                    console.log('status: statusEvent = ', statusEvent);
                },
                message: (messageEvent) => {
                    let {message} = messageEvent;
                    console.log('message: messageEvent = ', messageEvent);
                    console.log('message: message = ', message);
                    PubnubAPI.savePointToLocalStorage(message);
                    setDataMap(PubnubAPI.getLatestValuesMapFromLocalStorage());
                },
                presence: (presenceEvent) => {
                    // handle presence
                },
            });
        });
        return () => {
            pRef.current.unsubscribeAll();
        }
    }, [code]);

    if (users.length == 0) {
        return (
            <CmLoader/>
        )
    }
    // let d = {
    //     user: userData,
    //     metrics: dataMap[uuid]
    // }
    console.log('users = ', users);
    let goodUsers = users.filter(x => {
        if (x.email == undefined){
            return false;
        }
        return true;
    });

    return (
        <Wrapper>
            {/*{JSON.stringify(d)}*/}
            <InnerPlaceholder>

                <Grid>
                    {goodUsers.map((u, i) => {
                        let {uuid} = u;
                        let metrics = dataMap[uuid] || {};
                        return (
                            <Item key={uuid}>
                                <UserTileView
                                    {...u}
                                    user={u}
                                    metrics={metrics}
                                />
                            </Item>
                        )
                    })}
                </Grid>

            </InnerPlaceholder>

        </Wrapper>
    );
}

const Item = styled.div`
  padding: 10px;
  box-sizing: border-box;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Wrapper = styled.div`

`;

const InnerPlaceholder = styled.div`
  width: 1400px;
  padding: 10px;
  margin: 0 auto;
  box-sizing: border-box;
  @media (max-width: 1400px) {
    width: 100%;
  }
`;