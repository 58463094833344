import axios from 'axios'
import ls from 'local-storage'

const ENDPOINT = `https://api.integration.cardiomood.com`;


const CorsanoAPI = {

    async getUserProfile(uuid) {
        let pld = (await axios.get(`${ENDPOINT}/users/${uuid}/profile`)).data;
        return pld;
    },

    async getUsersInGroup(code) {
        let pld = (await axios.get(`${ENDPOINT}/group/${code}/users`)).data;
        return pld;
    },


}

export default CorsanoAPI;