import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import axios from 'axios'
import HeartCard from "./HeartCard";

import {STAB_HEART_RATE_ITEMS} from "../../../data/StabData";

export default function HeartRateDashboardTool(props) {
    let {items = STAB_HEART_RATE_ITEMS} = props;
    return (
        <Wrapper>

            {items.map((item, k) => {
                return (
                    <Item key={k}>
                        <HeartCard {...item} />
                    </Item>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    background: yellow;
`;

const Item = styled.div`
    flex: 1;
    flex-grow: 1;
    background: whitesmoke;
    align-self: stretch;
    border: 1px solid whitesmoke;
    box-sizing: border-box;
    min-width: 33.333%;
    padding: 5px;
    box-sizing: border-box;
`;
