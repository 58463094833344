import axios from 'axios'
import ls from 'local-storage'

import CorsanoAPI from './CorsanoAPI'

const ENDPOINT = `https://api.integration.cardiomood.com`;


const PubnubAPI = {

    async getConnectionData(uuid) {
        let pld = (await axios.get(`${ENDPOINT}/pubnub/users/${uuid}/token`)).data;
        return pld;
    },

    async getConnectionDataForGroup(code) {
        let pld = (await axios.get(`${ENDPOINT}/group/${code}/users`)).data;
        return pld;
    },


    getUsersMapFromLocalStorage() {
        let allKeys = Object.keys(window.localStorage);
        console.log('allKeys = ', allKeys);
        let keys = allKeys.filter(x => (x.indexOf(`live-`) > -1));
        let map = {};
        for (let i in keys) {
            let xKey = keys[i];
            let val = (ls.get(xKey) || {
                uuid: xKey.replace('live-', ''),
                points: []
            });
            map[val.uuid] = val;
        }
        return map;
    },

    savePointToLocalStorage(data) {
        if (data == undefined) {
            return;
        }
        let {uuid} = data;
        let key = `live-${uuid}`;
        let arr = (ls.get(key) || {
            uuid: uuid,
            points: []
        });
        let newPoints = arr.points.concat([data]).sort((a, b) => (+a.t - +b.t)).slice(-400);
        ls.set(key, {
            uuid: uuid,
            points: newPoints
        })
    },

    getLatestValuesMapFromLocalStorage() {
        let xMap = this.getUsersMapFromLocalStorage();
        console.log('getLatestValuesMapFromLocalStorage: xMap = ', xMap);
        let map = {};
        for (let key in xMap) {
            let v = xMap[key];
            if (v == undefined || v.points == undefined || v.points.length == 0) {
                continue;
            }
            let lp = v.points[v.points.length - 1];
            map[key] = lp;
        }
        return map;
    }

}

export default PubnubAPI;